import React, { useContext, useEffect } from 'react'
import styles from './MainLayout.module.sass'
import Burger from "../Burger/Burger"
import Menu from "../Menu/Menu"
import Footer from "../Footer/Footer"
import AppContext from "../../context/AppContext"
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher"

function MainLayout({ children }) {
  const { scroll } = useContext(AppContext)

  useEffect(() => {
    setTimeout(() => {
      scroll.scrollToTop({ smooth: true })
    }, 150)
  }, [])

  return (
    <div className={styles.mainLayout}>
      <Menu />
      <Burger />
      <div className={styles.langSwitcher}>
        <LanguageSwitcher />
      </div>

      {/*<div className={styles.content}>*/}
        { children }
      {/*</div>*/}

      <Footer />
    </div>
  )
}

export default MainLayout
