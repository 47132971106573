import React, { useContext } from 'react'
import styles from './OurPartners.module.sass'
import SectionTitle from "../../SectionTitle/SectionTitle"
import perekrestok from '../../../images/partners/perekrestok.svg'
import pyaterochka from '../../../images/partners/pyaterochka.svg'
import karusel from '../../../images/partners/karusel.svg'
import magnit from '../../../images/partners/magnit.svg'
import auchan from '../../../images/partners/auchan.svg'
import wildberries from '../../../images/partners/wildberries.svg'
import ozon from '../../../images/partners/ozon.svg'
import atak from '../../../images/partners/atak.svg'
import lenta from '../../../images/partners/lenta.png'
import dixi from '../../../images/partners/dixi.svg'
import av from '../../../images/partners/av.svg'
import magnoliya from '../../../images/partners/magnoliya.png'
import tvoydom from '../../../images/partners/tvoydom.png'
import fixPrice from '../../../images/partners/fix-price.svg'
import eurospar from '../../../images/partners/eurospar.svg'
import foodCity from '../../../images/partners/food-city.svg'
import aromatniyMir from '../../../images/partners/logo-aromatniy-mir.svg'
import samokat from '../../../images/partners/samokat.svg'
import AppContext from "../../../context/AppContext"
import partner24 from '../../../images/partners/24.svg'
import partner25 from '../../../images/partners/25.svg'
import partner26 from '../../../images/partners/26.svg'
import partner27 from '../../../images/partners/27.svg'
import partner28 from '../../../images/partners/28.svg'
import partner29 from '../../../images/partners/29.svg'
import partner30 from '../../../images/partners/30.svg'
import partner31 from '../../../images/partners/31.svg'
import partner32 from '../../../images/partners/32.svg'
import partner33 from '../../../images/partners/lukoil.png'
import partner34 from '../../../images/partners/34.svg'
import partner35 from '../../../images/partners/35.svg'
import partner36 from '../../../images/partners/36.svg'
import partner37 from '../../../images/partners/37.svg'
import partner38 from '../../../images/partners/38.svg'
import partner39 from '../../../images/partners/39.svg'
import partner40 from '../../../images/partners/40.svg'
import partner41 from '../../../images/partners/41.svg'
import partner44 from '../../../images/partners/44.svg'
import partner45 from '../../../images/partners/45.svg'

function OurPartners() {
  const { t } = useContext(AppContext)

  return (
    <section className={styles.section}>
      <SectionTitle text={t('ourPartners')} />

      <div className={styles.logos}>
        <div className={styles.innerContainer}>
          <img className={styles.img} src={perekrestok} alt="" />
          <img className={styles.img} src={pyaterochka} alt="" />
          <img className={styles.img} src={karusel} alt="" />
          <img className={styles.img} src={magnit} alt="" />
          <img className={styles.img} src={auchan} alt="" />
          <img className={styles.img} src={wildberries} alt="" />
          <img className={styles.img} src={ozon} alt="" />
          <img className={styles.img} src={atak} alt="" />
          <img className={styles.img} src={lenta} alt="" />
          <img className={styles.img} src={dixi} alt="" />
          <img className={styles.img} src={av} alt="" />
          <img className={styles.img} src={magnoliya} alt="" />
          <img className={styles.img} src={tvoydom} alt="" />
          <img className={styles.img} src={fixPrice} alt="" />
          <img className={styles.img} src={eurospar} alt="" />
          <img className={styles.img} src={foodCity} alt="" />
          <img className={styles.img} src={aromatniyMir} alt="" />
          <img className={styles.img} src={samokat} alt="" />
          <img className={styles.img2} src={partner24} alt="" />
          <img className={styles.img2} src={partner25} alt="" />
          <img className={styles.img2} src={partner26} alt="" />
          <img className={styles.img2} src={partner27} alt="" width="110" />
          <img className={styles.img2} src={partner28} alt="" />
          <img className={styles.img2} src={partner29} alt="" />
          <img className={styles.img2} src={partner30} alt="" />
          <img className={styles.img2} src={partner31} alt="" />
          <img className={styles.img2} src={partner32} alt="" />
          <img className={styles.img2} src={partner33} alt="" />
          <img className={styles.img2} src={partner34} alt="" />
          <img className={styles.img2} src={partner35} alt="" />
          <img className={styles.img2} src={partner36} alt="" />
          <img className={styles.img2} src={partner37} alt="" />
          <img className={styles.img2} src={partner38} alt="" />
          <img className={styles.img2} src={partner39} alt="" />
          <img className={styles.img2} src={partner40} alt="" />
          <img className={styles.img2} src={partner41} alt="" />
               <img className={styles.img2} src={partner44} alt="" />
          <img className={styles.img2} src={partner45} alt="" />
        
        </div>
        
      </div>

    </section>
  )
}

export default OurPartners
