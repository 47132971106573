import React, { useContext, useEffect } from 'react'
import styles from './AboutFirstScreenSection.module.sass'
import SectionTitle from "../../SectionTitle/SectionTitle"
import WantToTeam from "../../WantToTeam/WantToTeam"
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs"
import { motion } from "framer-motion"
import AppContext from "../../../context/AppContext"
import Adversus_Presentation_Ru from '../../../presentations/Adversus_Presentation(ru).pdf'
import Adversus_Presentation_En from '../../../presentations/Adversus_Presentation(en).pdf'

function AboutFirstScreenSection() {
	const { t, setIsBurgerDark } = useContext(AppContext)

	const textVariants = {
		hide: {
			opacity: 0,
			scale: 0.95
		},

		show: {
			opacity: 1,
			scale: 1,
			transition: { duration: 0.5 }
		}
	}

	useEffect(() => {
		window.addEventListener("mousewheel", () => {
			if (window.scrollY >= window.screen.height / 3) return setIsBurgerDark(false)
			return setIsBurgerDark(true)
		})
	}, [])

	return (
		<section className={styles.section}>
			<div className={styles.symbol} />

			<div className={styles.logo} />

			<div className={styles.leftCol}>
				<div className={styles.content}>
					<BreadCrumbs />

					<SectionTitle dark text={t('aboutFirstScreenSection.title')} />

					<motion.div
						variants={textVariants}
						initial="hide"
						whileInView="show"
						className={styles.texts}>
						<p>
							{t('aboutFirstScreenSection.text.partOne.span') + ' ' + t('aboutFirstScreenSection.text.partOne.text')}
						</p>

						<p>
							{/* <span>{ t('aboutFirstScreenSection.text.partTwo.spanOne') + ' ' }</span> */}
							{t('aboutFirstScreenSection.text.partTwo.text')}
							{/* <span>{ ' ' + t('aboutFirstScreenSection.text.partTwo.spanTwo') }</span> */}
						</p>

						<p>
							{t('aboutFirstScreenSection.text.partThree.textOne')}
							{/* <span>{ t('aboutFirstScreenSection.text.partThree.span') + ' ' }</span> */}
							{t('aboutFirstScreenSection.text.partThree.textTwo')}
						</p>

						{/*<p>*/}
						{/*  { t('aboutFirstScreenSection.text.partFour.textOne') }*/}
						{/*  <span>{ t('aboutFirstScreenSection.text.partFour.span') }</span>*/}
						{/*  { ' ' + t('aboutFirstScreenSection.text.partFour.textTwo') }*/}
						{/*</p>*/}

						<p>{t('aboutFirstScreenSection.text.partFive.text')}</p>

						<p>
							{t('aboutFirstScreenSection.text.partSix.text')}
						</p>
					</motion.div>

					<WantToTeam light />

					<div className={styles.presentation}>
						<a style={{ color: 'black' }} download href={(t('presentation') === 'Смотреть презентцию продукции') ? Adversus_Presentation_Ru : Adversus_Presentation_En}> <p>{t('presentation')}</p></a>
					</div>
				</div>

			</div>

			<div className={styles.rightCol}>
				<div className={styles.imgTop} />
				<div className={styles.imgBottom} />
			</div>
		</section>
	)
}

export default AboutFirstScreenSection
