import React, { useContext } from 'react'
import styles from './BizonSection.module.sass'
import SliderSectionContent from "../../SliderSectionContent/SliderSectionContent"
import AdversusLogo from "../../AdversusLogo/AdversusLogo"
import exotic from '../../../images/bizon/exotic_449.png'
import black from '../../../images/bizon/black_449.png'
import raspberry from '../../../images/bizon/raspberry_449.png'
import { motion } from "framer-motion"
import AppContext from "../../../context/AppContext"

function BizonSection() {
	const { t, pageSlider } = useContext(AppContext)

	const bgVariants = {
		hide: {
			opacity: 0,
			// scale: 0.8
		},

		show: {
			opacity: 1,
			// scale: 1
		}
	}

	const exoticVariants = {
		hide: {
			opacity: 0,
			// x: -100
		},

		show: {
			opacity: 1,
			// x: 0
		}
	}

	const bizonVariants = {
		hide: {
			opacity: 0,
			// y: 150
		},

		show: {
			opacity: 1,
			// y: 0
		}
	}

	const raspberryVariants = {
		hide: {
			opacity: 0,
			// x: 150
		},

		show: {
			opacity: 1,
			// x: 0
		}
	}

	const ellipseVariants = {
		hide: {
			opacity: 0
		},

		show: {
			opacity: 1
		}
	}

	const classes = {
		section: styles.section,
		active: pageSlider === 2
			? styles.active
			: null
	}

	return (
		<section className={`${classes.section} ${classes.active}`}>
			{/*<AdversusLogo />*/}
			<motion.div
				variants={bgVariants}
				initial="hide"
				whileInView="show"
				transition={{ duration: 0.8, delay: 0.1 }}
				className={styles.background}>
				<div
					// variants={bgVariants}
					// initial="hide"
					// whileInView="show"
					// transition={{ duration: 0.8, delay: 0.3 }}
					className={styles.flashCircle} />
			</motion.div>

			<SliderSectionContent
				link={'https://bizon-energy.ru/'}
				text={[
					t('bizon.partOne'),
					t('bizon.partTwo')
				]}>
				<div className={styles.logo} />
			</SliderSectionContent>

			<motion.img
				variants={exoticVariants}
				initial="hide"
				whileInView="show"
				transition={{ duration: 0.3, delay: 0.2 }}
				className={styles.exotic} src={exotic} alt="Exotic" />

			<motion.img
				variants={bizonVariants}
				initial="hide"
				whileInView="show"
				transition={{ duration: 0.3, delay: 0.3 }}
				className={styles.bottle} src={black} alt="Bottle" />

			<motion.img
				variants={raspberryVariants}
				initial="hide"
				whileInView="show"
				transition={{ duration: 0.3, delay: 0.4 }}
				className={styles.raspberry} src={raspberry} alt="Raspberry" />

			<motion.div
				variants={ellipseVariants}
				initial="hide"
				whileInView="show"
				transition={{ delay: 0.6 }}
				className={styles.ellipse} />
			<motion.div
				variants={ellipseVariants}
				initial="hide"
				whileInView="show"
				transition={{ delay: 0.7 }}
				className={`${styles.ellipse} ${styles.ellipse_small}`} />
			<motion.div
				variants={ellipseVariants}
				initial="hide"
				whileInView="show"
				transition={{ delay: 0.8 }}
				className={`${styles.ellipse} ${styles.ellipse_mid}`} />

		</section>
	)
}

export default BizonSection
