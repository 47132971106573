import React, { useContext } from 'react'
import styles from './DescriptionSection.module.sass'
import SectionTitle from "../../SectionTitle/SectionTitle"
import coffee from '../../../images/description/coffee.png'
import WantToTeam from "../../WantToTeam/WantToTeam"
import { motion } from "framer-motion"
import AdversusLogo from "../../AdversusLogo/AdversusLogo"
import AppContext from "../../../context/AppContext"

function DescriptionSection() {
	const { t } = useContext(AppContext)

	const textVariants = {
		hide: {
			opacity: 0,
			x: -50
		},

		show: {
			opacity: 1,
			x: 0,
			transition: { duration: 0.3 }
		}
	}

	const coffeeIngVariants = {
		hide: {
			opacity: 0,
			x: 150,
			rotate: 5
		},

		show: {
			opacity: 1,
			x: 0,
			transition: { duration: 0.4 }
		}
	}

	return (
		<section className={styles.section}>
			{/*<div className={styles.line} />*/}

			<div className={styles.logo_mob}>
				<AdversusLogo />
			</div>

			<div className={styles.title_mob}>
				<SectionTitle text={t('descriptionSection.title')} />
			</div>

			<div className={styles.leftCol}>
				<div className={styles.title_desktop}>
					<SectionTitle text={t('descriptionSection.title')} />
				</div>

				<div className={styles.texts}>
					<motion.p
						variants={textVariants}
						initial="hide"
						whileInView="show"
						className={styles.text}>
						{/* <span>{t('descriptionSection.description.partOne.span')}</span> */}
						{t('descriptionSection.description.partOne.text')}
					</motion.p>

					<motion.p
						variants={textVariants}
						initial="hide"
						whileInView="show"
						className={styles.text}>
						{t('descriptionSection.description.partTwo.text')}
						{/* <span>{t('descriptionSection.description.partTwo.span')}</span> */}
					</motion.p>

					<motion.p
						variants={textVariants}
						initial="hide"
						whileInView="show"
						className={styles.text}>
						{t('descriptionSection.description.partThree.textOne')}
						{/* <span>{' ' + t('descriptionSection.description.partThree.span')}</span> */}
						{t('descriptionSection.description.partThree.textTwo')}
					</motion.p>

					<motion.p
						variants={textVariants}
						initial="hide"
						whileInView="show"
						className={styles.text}>
						{t('descriptionSection.description.partFour.text')}
						{/* <span>{t('descriptionSection.description.partFour.span')}</span>. */}
					</motion.p>

					<motion.p
						variants={textVariants}
						initial="hide"
						whileInView="show"
						className={styles.text} >
						{t('descriptionSection.description.partFive.text')}.
					</motion.p>
				</div>

				<WantToTeam />
			</div>

			<div className={styles.rightCol}>
				<motion.img
					variants={coffeeIngVariants}
					initial="hide"
					whileInView="show"
					className={styles.coffee} src={coffee} alt="Coffee-Cola" />

				{/*<div className={styles.starSmallTop} />*/}
				{/*<div className={styles.starBig} />*/}
			</div>

			{/*<div className={styles.starMid}/>*/}
		</section>
	)
}

export default DescriptionSection
