import React from 'react'
import styles from './PhotoSection.module.sass'
import drinkRed from '../../../images/photo-section/drink-red.png'
import man from '../../../images/photo-section/man.png'
import girl from '../../../images/photo-section/girl.png'
import drink from '../../../images/photo-section/drink.png'
import barmen from '../../../images/photo-section/barmen.png'
import { motion } from "framer-motion"

function PhotoSection() {
  const variants = {
    hide: {
      opacity: 0,
      scale: 0.5,
    },
    show: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5
      }
    }
  }

  return (
    <section className={styles.section}>
      <img className={styles.image} src={drinkRed} alt=""/>
      <img className={styles.image} src={man} alt=""/>
      <img className={styles.image} src={girl} alt=""/>
      <img className={styles.image} src={drink} alt=""/>
      <img className={styles.image} src={barmen} alt=""/>

      {/*<motion.div*/}
      {/*  variants={variants}*/}
      {/*  initial="hide"*/}
      {/*  whileInView="show"*/}
      {/*  className={styles.star} />*/}
      {/*<motion.div*/}
      {/*  variants={variants}*/}
      {/*  initial="hide"*/}
      {/*  whileInView="show"*/}
      {/*  className={styles.starSmall} />*/}
    </section>
  )
}

export default PhotoSection
