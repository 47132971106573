import React, { useContext } from 'react'
import styles from './Footer.module.sass'
import logo from '../../images/footer/logo.svg'
import Navigation from "../Navigation/Navigation"
import { motion } from "framer-motion"
import AppContext from "../../context/AppContext"

function Footer() {
	const { t } = useContext(AppContext)

	const logoVariants = {
		hide: {
			opacity: 0
		},

		show: {
			opacity: 1,
			transition: { duration: 0.5 }
		}
	}

	const copyrightVariants = {
		hide: {
			opacity: 0,
			y: 20
		},

		show: {
			opacity: 1,
			y: 0,
			transition: { delay: 0.3, duration: 0.5 }
		}
	}

	const address = {
		hide: {
			opacity: 0,
			x: 50
		},

		show: {
			opacity: 1,
			x: 0,
			transition: { delay: 0.4, duration: 0.3 }
		}
	}

	return (
		<footer className={styles.footer}>
			<motion.img
				variants={logoVariants}
				initial="hide"
				whileInView="show"
				className={styles.logo} src={logo} alt="" />

			<div className={styles.wrapper}>
				<Navigation left />

				<motion.div
					variants={copyrightVariants}
					initial="hide"
					whileInView="show"
					className={styles.copyright} >
					{t('footer.copyright')}
				</motion.div>

				<motion.div
					variants={address}
					initial="hide"
					whileInView="show"
					className={styles.contacts}>
					<p>{t('footer.address')}</p>
					<p>{t('footer.index')}</p>

					<a href="tel:+7 (800) 600-25-88">+7 (800) 600-25-88</a>
					<a href="tel:+7 (926) 518-88-88">+7 (926) 518-88-88</a>
					<a href="mailto:info@adversus-td.ru">info@adversus-td.ru</a>
				</motion.div>
			</div>
		</footer>
	)
}

export default Footer
