import React from 'react'
import styles from './SliderSectionContent.module.sass'
import Button from "../Button/Button"
import { motion } from "framer-motion"

function SliderSectionContent({ children, text, link, gradient }) {
  const titleVariants = {
    hide: {
      opacity: 0,
      x: -100
    },

    show: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.8 }
    }
  }

  const textVariants = {
    hide: {
      opacity: 0,
      x: -150
    },

    show: {
      opacity: 1,
      x: 0
    }
  }

  return (
    <div className={styles.content}>
      <motion.div
        variants={titleVariants}
        initial="hide"
        whileInView="show"
      >{ children }</motion.div>

      <div className={styles.texts}>
        { text.map((t, i) => (
          <motion.p
            variants={textVariants}
            initial="hide"
            whileInView="show"
            transition={{ duration: 0.5 }}
            key={Math.random() * i}
            className={styles.text}>{ t }</motion.p>
        )) }
      </div>

      <a className={styles.link} href={link} target="_blank" rel="noreferrer">
        <Button text={'Перейти'} bg={'light'} gradient={gradient}  />
      </a>

    </div>
  )
}

export default SliderSectionContent
