import React from 'react'

function PreloaderSymbol({ fill }) {
  return (
    <svg width="191" height="163" viewBox="0 0 191 163" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        style={{ transition: '300ms' }}
        d="M59.6834 108.675C59.149 109.948 58.6137 111.252 58.0473 112.555C57.4799 113.889 56.9135 115.193 56.4102 116.466C55.9166 117.394 55.5172 118.294 55.15 119.163C54.8139 120.033 54.477 120.934 54.1392 121.867C53.3068 123.759 52.6311 125.624 52.1105 127.525C51.5898 129.426 51.2899 131.239 51.2412 132.996C51.175 135.381 52.0042 136.974 53.6985 137.743C55.3928 138.512 58.9054 138.955 64.2043 139.102L70.9993 139.291L70.4498 159.089L0.816508 157.156L1.36601 137.358C7.62591 137.72 12.569 137.198 16.2248 135.855C19.8485 134.543 23.1037 130.771 25.9256 124.632C26.5882 123.237 27.2516 121.811 27.9142 120.417C28.5777 118.991 29.2385 117.659 29.8976 116.39C30.0665 115.923 30.2328 115.551 30.3983 115.21C30.5628 114.901 30.7274 114.591 30.8616 114.25C31.8455 112.519 32.7982 110.787 33.6877 109.084C34.6092 107.351 35.4078 105.552 36.0843 103.656L80.2573 0.162179L115.884 1.15101L161.494 116.745C162.332 119.155 163.208 121.314 163.996 123.251C164.816 125.19 165.579 126.938 166.313 128.56C168.676 133.367 171.478 136.898 174.725 139.061C177.972 141.223 183.243 142.375 190.567 142.578L190.018 162.376L103.241 159.968L103.791 140.169L111.988 140.397C116.69 140.716 120.132 140.34 122.342 139.365C124.584 138.391 125.688 136.821 125.746 134.719C125.791 133.118 125.532 131.227 124.966 129.108C124.401 126.988 123.743 124.866 123.022 122.742C122.728 122.106 122.434 121.439 122.14 120.802C121.847 120.135 121.555 119.405 121.265 118.611C120.683 117.119 120.099 115.659 119.517 114.167C118.933 112.706 118.439 111.405 118.003 110.263L59.6843 108.644L59.6834 108.675ZM89.1384 36.4561L67.7337 88.3631L109.719 89.5284L89.1384 36.4561Z"
        fill={fill}/>
    </svg>
  )
}

export default PreloaderSymbol
