import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { HashRouter } from "react-router-dom"
import './index.css'
import App from './components/App/App'
import './i18n'

ReactDOM.render(
  <Suspense fallback={<div>Loading...</div>}>
    <HashRouter>
      <App />
    </HashRouter>
  </Suspense>,
  document.getElementById('root')
)
