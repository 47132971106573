import React from 'react'
import styles from './AdversusLogo.module.sass'

function AdversusLogo() {
  return (
    <div className={styles.logo} />
  )
}

export default AdversusLogo
