import React, { useContext } from 'react'
import styles from './BreadCrumbs.module.sass'
import { Link } from "react-router-dom"
import { ROUTES } from "../../utils/ROUTES"
import { useLocation } from 'react-router-dom'
import AppContext from "../../context/AppContext"

function BreadCrumbs({ dark }) {
  const { t } = useContext(AppContext)
  const { MAIN, ABOUT, CONTACTS } = ROUTES
  const location = useLocation()

  const classes = {
    link: dark
      ? `${styles.link} ${styles.link_theme_dark}`
      : styles.link,

    linkActive: dark
      ? `${styles.link} ${styles.link_theme_dark} ${styles.link_active}`
      : `${styles.link} ${styles.link_active}`
  }

  const linkTo = () => {
    switch(location.pathname) {
      case ABOUT: return { link: ABOUT, text: t('breadCrumbs.about') }
        break
      case CONTACTS: return { link: CONTACTS, text: t('breadCrumbs.contacts') }
        break
      default: return MAIN
    }
  }

  const { link, text } = linkTo()

  return (
    <nav className={styles.breadCrumbs}>
      <ul>
        <li>
          <Link
            className={classes.link}
            to={MAIN}>{ t('breadCrumbs.main') }</Link>
          <Link
            className={classes.linkActive}
            to={link}>{ text }</Link>
        </li>
      </ul>
    </nav>
  )
}

export default BreadCrumbs
