import React from 'react'
import styles from './Input.module.sass'
import InputMask from 'react-input-mask'

function Input({ type, name, value, onChange, placeholder, required, dark }) {
  const textInput =
    <input
      value={value}
      onChange={onChange}
      className={ dark ? `${styles.input} ${styles.input_dark}` : styles.input}
      type={type}
      name={name}
      placeholder={placeholder}
      required={required}
    />

    const textareaInput =
      <textarea
        value={value}
        onChange={onChange}
        className={`${styles.input} ${styles.input_dark} ${styles.input_type_textarea}`}
        type={type}
        name={name}
        placeholder={placeholder}
        required={required}
      />

  const phoneInput =
    <InputMask
      value={value}
      onChange={onChange}
      className={`${styles.input} ${styles.input_type_phone}`}
      type={type}
      name={name}
      placeholder={placeholder}
      required={required}
      mask='+7 999 999 99 99'
    />

  const emailInput =
    <input
      value={value}
      onChange={onChange}
      className={ dark ? `${styles.input} ${styles.input_dark} ${styles.input_type_email}` : `${styles.input} ${styles.input_type_email}`}
      type={type}
      name={name}
      placeholder={placeholder}
      required={required}
    />

    const renderInput = () => {
      switch(type) {
        case 'text': return textInput
          break

        case 'phone': return phoneInput
          break

        case 'email': return emailInput
          break

        case 'textarea': return textareaInput
          break

        default: return textInput
          break
      }
    }

  return (
    renderInput()
  )
}

export default Input
