import React from 'react'
import MainLayout from "../components/MainLayout/MainLayout"
import AboutFirstScreenSection from "../components/sections/AboutFirstScreenSection/AboutFirstScreenSection"
import HistorySection from "../components/sections/HistorySection/HistorySection"
import OurPartners from "../components/sections/OurPartners/OurPartners"

function AboutPage() {
  return (
    <MainLayout>
      <AboutFirstScreenSection />
      <HistorySection />
      <OurPartners />
    </MainLayout>
  )
}

export default AboutPage
