import React, { useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import styles from './WantToTeam.module.sass'
import Button from "../Button/Button"
import Ellipse from "../Ellipse/Ellipse"
import StarSmall from "../StarSmall/StarSmall"
import { motion } from "framer-motion"
import { Link } from 'react-scroll'
import { ROUTES } from "../../utils/ROUTES"
import AppContext from "../../context/AppContext"

function WantToTeam({ light }) {
  const { t } = useContext(AppContext)

  const variants = {
    hide: {
      opacity: 0,
      x: 50
    },

    show: {
      opacity: 1,
      x: 0,
      transition: { delay: 0.2 }
    }
  }

  const { MAIN, CONTACTS } = ROUTES

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const onLinkClick = () => {
    if (pathname === MAIN) return
    navigate(CONTACTS)
  }

  return (
    <div className={styles.actions}>
      <Link
        onClick={onLinkClick}
        duration={500}
        smooth={true}
        to={ pathname === MAIN ? 'feedback' : '' }>
        <div className={styles.btn}>
          <Button text={ t('wantToTeam.button') } bg={!light ? 'light' : 'dark'} />
        </div>
      </Link>

      <motion.p
        variants={variants}
        initial="hide"
        whileInView="show"
        className={ light ? `${styles.mission} ${styles.mission_text_black}` : styles.mission}>
			  {/* <span>{t('wantToTeam.text.span')}</span> */}
			  {t('wantToTeam.text.text')}

        {/*<Ellipse isLight={!light} />*/}
        {/*<StarSmall isLight={light} />*/}
      </motion.p>
    </div>
  )
}

export default WantToTeam
