import React from 'react'
import style from './Logo.module.sass'
import { motion } from "framer-motion"

function Logo() {

  const variants = {
    hide: {
      x: -150,
      opacity: 0,
      rotate: window.innerWidth <= 425 ? 23 : 33
    },

    show: {
      x: 0,
      opacity: 1
    }
  }

  return (
    <motion.div
      variants={variants}
      initial="hide"
      whileInView="show"
      className={style.logo}>
       <div className={style.symbol} />
    </motion.div>
  )
}

export default Logo
