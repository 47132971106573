import React, { useContext } from 'react'
import { useLocation } from "react-router-dom"
import styles from './LanguageSwitcher.module.sass'
import AppContext from "../../context/AppContext"
import { ROUTES } from "../../utils/ROUTES"
import EllipseLang from "../EllipseLang/EllipseLang"

function LanguageSwitcher() {
  const { lang, setLang, isBurgerDark } = useContext(AppContext)
  const { ABOUT } = ROUTES
  const { pathname } = useLocation()

  const handleItemClick = evt => {
    const textContent = evt.target.textContent.toLowerCase()

    if (lang === textContent) return
    if (textContent === 'eng') return setLang('eng')
    setLang('rus')
  }

  const classes = {
    ellipse: lang === 'rus'
      ? `${styles.ellipse} ${styles.ellipse_lang_rus}`
      : styles.ellipse,

    listItem: pathname === ABOUT
      ? `${styles.item} ${styles.item_page_contacts}`
      : styles.item,

    scrollDown: isBurgerDark && pathname === ABOUT
      ? ''
      : styles.item_page_contacts_scroll_down
  }

  return (
    <div className={styles.container}>
      <ul className={styles.list}>
        <li onClick={handleItemClick} className={`${classes.listItem} ${classes.scrollDown}`}>Eng</li>
        <li onClick={handleItemClick} className={`${classes.listItem} ${classes.scrollDown}`}>Rus</li>
      </ul>

      <EllipseLang color={pathname === ABOUT && isBurgerDark ? '#000' : '#fff'} />
    </div>
  )
}

export default LanguageSwitcher
