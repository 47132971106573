import React from 'react'
import MainLayout from "../components/MainLayout/MainLayout"
import ContactUsFirstScreenSection
  from "../components/sections/ContactUsFirstScreenSection/ContactUsFirstScreenSection"
import DepartmentsSection from "../components/sections/DepartmentsSection/DepartmentsSection";

function ContactsPage() {
  return (
    <MainLayout>
      <ContactUsFirstScreenSection />
      <DepartmentsSection />
    </MainLayout>
  )
}

export default ContactsPage
