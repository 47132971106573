import React, { useContext } from 'react'
import styles from './FirstScreenSectionNew.module.sass'
import { motion } from "framer-motion"
import logo from '../../../images/logo-large.svg'
import AppContext from "../../../context/AppContext"
import Particle from "../../Particle/Particle"

function FirstScreenSectionNew() {
  const { t } = useContext(AppContext)

  const titleVariants = {
    hide: {
      opacity: 0,
      y: 100
    },

    show: {
      opacity: 1,
      y: 0
    }
  }

  const imgVariants = {
    hide: {
      opacity: 0
    },

    show: {
      opacity: 1
    }
  }

  const bankVariants = ({...axisStart}, {...axisStop}, rotate, duration, delay ) => {
    return {
      hide: {
        ...axisStart,
        rotate,
        opacity: 0
      },

      show: {
        ...axisStop,
        rotate,
        opacity: 1,
        transition: {
          duration,
          delay
        }
      }
    }
  }

  return (
    <section className={styles.section}>
      <div className={styles.backGround} />
      <div className={styles.backGroundMob} />

      <Particle />

      <div className={styles.wrapper}>
        <motion.img
          variants={imgVariants}
          initial="hide"
          whileInView="show"
          transition={{ delay: 0.3, duration: 0.7 }}
          className={styles.logoCenter}
          src={logo} alt="Logo"/>

        <motion.h1
          variants={titleVariants}
          initial="hide"
          whileInView="show"
          transition={{ delay: 0.5, duration: 0.7 }}
          className={styles.title}>
          { t('mainTitle') }
        </motion.h1>
      </div>

      <motion.div
        variants={bankVariants(
          { x: -50 },
          { x: 0 },
          16,
          0.3,
          0.2
        )}
        initial="hide"
        whileInView="show"
        className={styles.exotic} >
        <div className={styles.exotic_fade}>

        </div>
      </motion.div>
      <motion.div
        variants={bankVariants(
          { x: -50 },
          { x: 0 },
          -14,
          0.3,
          0.4
        )}
        initial="hide"
        whileInView="show"
        className={styles.coffee} />
      <motion.div
        variants={bankVariants(
          { x: -50 },
          { x: 0 },
          16,
          0.3,
          0.6
        )}
        initial="hide"
        whileInView="show"
        className={styles.energy} />



      <div className={styles.raspberry} />
      <motion.div className={styles.energyBlur} />

      <motion.div className={styles.ice} />
      <motion.div className={styles.iceBlur} />

    </section>
  )
}

export default FirstScreenSectionNew
