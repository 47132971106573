import React, { useContext } from 'react'
import styles from './EllipseLang.module.sass'
import AppContext from "../../context/AppContext"

function EllipseLang({ color = 'white' }) {
  const { lang } = useContext(AppContext)

  const classes = {
    ellipse: lang === 'rus'
      ? `${styles.ellipse} ${styles.ellipse_lang_rus} ${styles.ellipse}`
      : styles.ellipse
  }

  return (
    <svg className={classes.ellipse} width="47" height="36" viewBox="0 0 47 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.7"
            d="M44.7447 7.97776C46.4136 11.568 45.6398 15.9622 42.8892 20.2116C40.143 24.4544 35.4628 28.4826 29.4874 31.2602C23.5119 34.0378 17.4154 35.0189 12.4015 34.3833C7.37975 33.7466 3.52163 31.5055 1.85278 27.9152C0.183938 24.325 0.957717 19.9308 3.70826 15.6814C6.45451 11.4386 11.1347 7.41038 17.1101 4.6328C23.0856 1.85522 29.1821 0.874075 34.196 1.50972C39.2178 2.14636 43.0759 4.38754 44.7447 7.97776Z"
            stroke={color} />
    </svg>
  )
}

export default EllipseLang
