import React, { useContext } from 'react'
import styles from './DepartmentsSection.module.sass'
import DepartmentCard from "../../DepartmentCard/DepartmentCard"
import { motion } from "framer-motion"
import AppContext from "../../../context/AppContext"

function DepartmentsSection() {
  const { t } = useContext(AppContext)

  const list = {
    hide: {
      opacity: 0
    },

    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.15,
      },
    },
  }

  return (
    <motion.section
      variants={list}
      initial="hide"
      whileInView="show"
      className={styles.section}>
      <DepartmentCard
        title={ t('contactsPage.departments.retail') }
        phone={'+7 (800) 600-25-88'}
        email={'info@adversus-td.ru'}
      />
      <DepartmentCard
        title={ t('contactsPage.departments.networks') }
        phone={'+7 (800) 600-25-88'}
        email={'info@adversus-td.ru'}
      />
      <DepartmentCard
        title={ t('contactsPage.departments.mass') }
        phone={'+7 (800) 600-25-88'}
        email={'info@adversus-td.ru'}
      />
    </motion.section>
  )
}

export default DepartmentsSection
