import React from 'react'
import FirstScreenSection from "../components/sections/FirstScreenSection/FirstScreenSection"
import FirstScreenSectionNew from "../components/sections/FirstScreenSectionNew/FirstScreenSectionNew"
import MainLayout from "../components/MainLayout/MainLayout"
import CoffeeColaSection from "../components/sections/CoffeeColaSection/CoffeeColaSection"
import BizonSection from "../components/sections/BizonSection/BizonSection"
import FullEnergySection from "../components/sections/FullEnergySection/FullEnergySection"
import DescriptionSection from "../components/sections/DescriptionSection/DescriptionSection"
import FeedbackSection from "../components/sections/FeedbackSection/FeedbackSection"
import PhotoSection from "../components/sections/PhotoSection/PhotoSection"
// import FullScreenSlider from "../components/FullScreenSlider/FullScreenSlider"

function MainPage() {
  return (
    <>
      <MainLayout>
        {/*<FirstScreenSection />*/}

        <FirstScreenSectionNew />

        {/*<FullScreenSlider>*/}
        {/*  <CoffeeColaSection />*/}
        {/*  <BizonSection />*/}
        {/*  <FullEnergySection />*/}
        {/*</FullScreenSlider>*/}

        <CoffeeColaSection />
        <BizonSection />
        <FullEnergySection />

        <DescriptionSection />
        <FeedbackSection />
        <PhotoSection />

      </MainLayout>
    </>
  )
}

export default MainPage
