import React, { useState, useRef, useContext } from 'react'
import styles from './FeedbackSection.module.sass'
import SectionTitle from "../../SectionTitle/SectionTitle"
import Input from "../../Input/Input"
import FormSubmit from "../../FormSubmit/FormSubmit"
import emailjs from "@emailjs/browser"
import AppContext from "../../../context/AppContext"

function FeedbackSection() {
  const [formValues, setFormValues] = useState({
    name: '',
    phone: '',
    email: '',
  })

  const formRef = useRef()

  const { t, setIsSending } = useContext(AppContext)

  const handleSubmit = evt => {
    evt.preventDefault()
    console.log(formValues)

    setIsSending(true)

    emailjs.sendForm('service_lndg98q', 'template_gindpai', formRef.current, '4mDt7Zc_afsFIqr_0')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      })
      .finally(() => setIsSending(false))
  }

  const handleChange = evt => {
    const { name, value } = evt.target

    setFormValues(prev => {
      return { ...prev, [name]: value }
    })
  }

  return (
    <section id={'feedback'} className={styles.section}>
      <div className={styles.container}>
        <SectionTitle text={ t('feedbackSection.title') } dark />
        <form
          ref={formRef}
          onSubmit={handleSubmit}
          className={styles.form}>
          <Input
            required={true}
            onChange={handleChange}
            value={formValues.name}
            type={'text'}
            name={'name'}
            placeholder={ t('inputs.fullName') } />

          <div className={styles.phoneAndEmail}>
            <Input
              required={true}
              onChange={handleChange}
              value={formValues.phone}
              type={'phone'}
              name={'phone'}
              placeholder={'+7 (___) ___ - __-__'}
              phone />
            <Input
              required={true}
              onChange={handleChange}
              value={formValues.email}
              type={'email'}
              name={'email'}
              placeholder={'E-mail'}
              email />
          </div>

          <FormSubmit />
        </form>
      </div>
    </section>
  )
}

export default FeedbackSection
