import React, {useContext} from 'react'
import styles from './CoffeeColaSection.module.sass'
import coffeeCola from '../../../images/coffeecola-splash.png'
// import Button from "../../Button/Button"
import SliderSectionContent from "../../SliderSectionContent/SliderSectionContent"
// import AdversusLogo from "../../AdversusLogo/AdversusLogo"
import { motion } from "framer-motion"
import AppContext from "../../../context/AppContext"
import coffeeLogo from '../../../images/coffee-cola/image.webp'

function CoffeeColaSection() {
  const { t, pageSlider } = useContext(AppContext)

  const variantsBg = {
    hide: {
      opacity: 0
    },

    show: {
      opacity: 1,
      transition: { delay: 0.2, duration: 0.3 }
    }
  }

  const itemsVariants = {
    hide: {
      opacity: 0
    },

    show: {
      opacity: 1
    }
  }

  const randomDelayOrDuration = (min, max) => {
    const delay = Math.random() * (max - min) + min
    return delay.toFixed(1)
  }

  const classes = {
    section: styles.section,
    active: pageSlider === 1
      ? styles.active
      : null
  }

  return (
    <section className={`${classes.section} ${classes.active}`}>
      <div className={styles.fadeTop} />
      <div className={styles.fadeBottom} />

      {/*<AdversusLogo />*/}
      <motion.img
        variants={variantsBg}
        initial="hide"
        whileInView="show"
        className={styles.coffeeImg} src={coffeeCola} alt=""/>

      {/*<div className={styles.content}>*/}
      {/*  <h2 className={styles.title}>Coffee Cola</h2>*/}
      {/*  <div className={styles.texts}>*/}
      {/*    <p className={styles.text}>Любимая кола со вкусом кофе.</p>*/}
      {/*    <p className={styles.text}>Coffee Cola — на шаг впереди!</p>*/}
      {/*  </div>*/}
      {/*  <Button text={'Перейти'} bg={'light'}  />*/}
      {/*</div>*/}

      <SliderSectionContent
        gradient
        link={'https://coffeecola.ru/'}
        text={[
          t('coffeeCola.partOne'),
          t('coffeeCola.partTwo')
        ]}>

        <img className={styles.logoCoffee} src={coffeeLogo} alt="Coffee Cola" />

        {/*<h2 className={styles.title}>Coffee Cola</h2>*/}
      </SliderSectionContent>

      <motion.div
        variants={itemsVariants}
        initial="hide"
        whileInView="show"
        transition={{ delay: randomDelayOrDuration(0.1, 0.6), duration: randomDelayOrDuration(0.1, 0.6) }}
        className={styles.iceBig} />
      <motion.div
        variants={itemsVariants}
        initial="hide"
        whileInView="show"
        transition={{ delay: randomDelayOrDuration(0.1, 0.6), duration: randomDelayOrDuration(0.1, 0.6) }}
        className={styles.iceSmall} />
      <motion.div
        variants={itemsVariants}
        initial="hide"
        whileInView="show"
        transition={{ delay: randomDelayOrDuration(0.1, 0.6), duration: randomDelayOrDuration(0.1, 0.6) }}
        className={styles.coffeeGrainBig} />
      <motion.div
        variants={itemsVariants}
        initial="hide"
        whileInView="show"
        transition={{ delay: randomDelayOrDuration(0.1, 0.6), duration: randomDelayOrDuration(0.1, 0.6) }}
        className={styles.coffeeGrainSmall} />
    </section>
  )
}

export default CoffeeColaSection
