import React from 'react'
import styles from './DepartmentCard.module.sass'
import { motion } from "framer-motion"

function DepartmentCard({ title, phone, email }) {
  const item = {
    hide: { opacity: 0, y: -30 },
    show: { opacity: 1, y: 0 },
  }

  return (
    <motion.div
      variants={item}
      className={styles.card}>
      <h3 className={styles.title}>{ title }</h3>

      <a href={`tel:${phone}`} className={styles.phone}>{ phone }</a>
      <a href={`mailto:${email}`} className={styles.email}>{ email }</a>
    </motion.div>
  )
}

export default DepartmentCard
