import React, {useContext} from 'react'
import styles from './Button.module.sass'
import { motion } from "framer-motion"

function Button({ type, text, bg, gradient }) {
  const addClass = () => {
    switch (bg) {
      case 'light': return styles.button_light
      break
      default: return undefined
      break
    }
  }

  const isGradient = () => {
    if (gradient) return styles.button_coffee
    return
  }

  const variants = {
    hide: {
      opacity: 0,
      x: -50
    },

    show: {
      opacity: 1,
      x: 0
    }
  }

  return (
    <motion.button
      type={type}
      variants={variants}
      initial="hide"
      whileInView="show"
      whileHover={{ opacity: 0.5 }}
      transition={{ duration: 0.3, delay: 0.1 }}
      className={`${styles.button} ${addClass()} ${isGradient()}`}>{ text }</motion.button>
  )
}

export default Button
