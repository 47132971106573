import React, { useContext } from 'react'
import styles from './Menu.module.sass'
import AppContext from "../../context/AppContext"
import Navigation from "../Navigation/Navigation"
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";

function Menu() {
  const { isBurgerActive } = useContext(AppContext)

  const classes = {
    menu: isBurgerActive
      ? `${styles.menu} ${styles.menu_active}`
      : styles.menu
  }

  return (
    <div className={classes.menu}>
      <Navigation />

      <div className={styles.langSwitch}>
        <LanguageSwitcher />
      </div>
    </div>
  )
}

export default Menu
