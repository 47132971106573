import React, { useEffect, useState } from "react"
import './App.css'
import * as Scroll from 'react-scroll'
import { Routes, Route } from 'react-router-dom'
import MainPage from "../../pages/MainPage"
import { ROUTES } from "../../utils/ROUTES"
import AppContext from "../../context/AppContext"
import AboutPage from "../../pages/AboutPage"
import ContactsPage from "../../pages/ContactsPage"
import Preloader from "../Preloader/Preloader"
import { useTranslation } from "react-i18next"

function App() {
  const [isBurgerActive, setIsBurgerActive] = useState(false)
  const [isBurgerDark, setIsBurgerDark] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [isSending, setIsSending] = useState(false)
  const [lang, setLang] = useState('rus')
  const [pageSlider, setPageSlider] = useState(1)

  const scroll = Scroll.animateScroll
  const { MAIN, ABOUT, CONTACTS } = ROUTES

  const { t, i18n } = useTranslation()

  const changeLanguage = (language) => i18n.changeLanguage(language)

  const appValue = {
    isBurgerActive,
    setIsBurgerActive,
    isBurgerDark,
    setIsBurgerDark,
    scroll,
    isSending,
    setIsSending,
    changeLanguage,
    t,
    lang,
    setLang,
    pageSlider,
    setPageSlider,
  }

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 1300)
  }, [])

  useEffect(() => {
    console.log(lang)
    changeLanguage(lang).catch(err => console.log(err))
  }, [lang])

  return (
    <div className="App">
      <AppContext.Provider value={appValue}>
        <Routes>
          <Route path={MAIN} element={<MainPage />} />
          <Route path={ABOUT} element={<AboutPage />} />
          <Route path={CONTACTS} element={<ContactsPage />} />
        </Routes>

        { isLoading && <Preloader /> }

      </AppContext.Provider>
    </div>
  )
}

export default App
