import React from 'react'
import styles from './SectionTitle.module.sass'
import { motion } from "framer-motion"

function SectionTitle({ text, dark }) {
  const classes = {
    title: dark
      ? `${styles.title} ${styles.title_dark}`
      : styles.title
  }

  const variants = {
    hide: {
      opacity: 0,
      x: -150
    },

    show: {
      opacity: 1,
      x: 0
    }
  }

  return (
    <motion.h2
      variants={variants}
      initial="hide"
      whileInView="show"
      className={classes.title}>{ text }</motion.h2>
  )
}

export default SectionTitle
