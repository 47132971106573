import React, { useContext } from 'react'
import styles from './HistorySection.module.sass'
import SectionTitle from "../../SectionTitle/SectionTitle"
import { Chart, registerables } from 'chart.js'
import { LineChart, Line, Tooltip, XAxis, ResponsiveContainer } from 'recharts'
import { motion } from "framer-motion"
import AppContext from "../../../context/AppContext"
import { Trans } from "react-i18next"

function HistorySection() {
  const { t } = useContext(AppContext)

  Chart.register(...registerables)

  const data = [
    {
      name: '2011',
      uv: 4000,
      pv: 400,
      amt: 2400,
    },

    {
      name: '2012',
      uv: 4000,
      pv: 340,
      amt: 2400,
    },
    {
      name: '2013',
      uv: 3000,
      pv: 400,
      amt: 2210,
    },
    {
      name: '2014',
      uv: 2000,
      pv: 470,
      amt: 2290,
    },
    {
      name: '2015',
      uv: 2780,
      pv: 500,
      amt: 2000,
    },
    {
      name: '2016',
      uv: 1890,
      pv: 510,
      amt: 2181,
    },
    {
      name: '2017',
      uv: 2390,
      pv: 480,
      amt: 2500,
    },
    {
      name: '2018',
      uv: 3490,
      pv: 460,
      amt: 2100,
    },
    {
      name: '2019',
      uv: 3490,
      pv: 400,
      amt: 2100,
    },
    {
      name: '2021',
      uv: 3490,
      pv: 430,
      amt: 2100,
    },
    {
      name: '2022',
      uv: 3490,
      pv: 480,
      amt: 2100,
    },
  ]

  const variants = {
    hide: {
      opacity: 0,
    },

    show: {
      opacity: 1,
      transition: { duration: 0.5 }
    }
  }

  const textVariants = {
    hide: {
      opacity: 0,
      x: 50,
    },

    show: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.5 }
    }
  }

  const getIntroOfPage = label => {
    switch(label) {
      case '2011': return ''
        break
      case '2012': return '2012'
        break
      case '2013': return '2013'
        break
      case '2014': return '2014'
        break
      case '2015': return '2015'
        break
      case '2016': return '2016'
        break
      case '2017': return '2017'
        break
      case '2018': return '2018'
        break
      case '2019': return '2019'
        break
      case '2021': return '2021'
        break
      case '2022': return ''
        break
      default: return ''
    }
  }

  const getTextOfTooltip = label => {
    switch(label) {
      case '2011': return ''
        break
      case '2012': return t('historySection.years.2012')
        break
      case '2013': return t('historySection.years.2013')
        break
      case '2014': return t('historySection.years.2014')
        break
      case '2015': return t('historySection.years.2015')
        break
      case '2016': return t('historySection.years.2016')
        break
      case '2017': return t('historySection.years.2017')
        break
      case '2018': return t('historySection.years.2018')
        break
      case '2019': return t('historySection.years.2019')
        break
      case '2021': return t('historySection.years.2021')
        break
      case '2022': return ''
        break
      default: return ''
    }
  }

  const getClassOfTooltip = label => {
    switch (label) {
      case '2011': return styles.tooltip_invisible
        break
      case '2022': return styles.tooltip_invisible
        break
      default: return styles.tooltip
    }
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className={getClassOfTooltip(label)}>
          {/*<p className="label">{`${label} : ${payload[0].value}`}</p>*/}
          <p className={styles.tooltipTitle}>{ getIntroOfPage(label) }</p>
          <p className={styles.tooltipText}>{ getTextOfTooltip(label) }</p>
        </div>
      );
    }

    return null
  }

  Chart.defaults.elements.line.tension = 0.3

  return (
    <section className={styles.section}>
      <SectionTitle text={ t('historySection.title') } />

      <motion.div
        variants={variants}
        initial="hide"
        whileInView="show"
        className={styles.info}>

        <ResponsiveContainer width={'100%'} height={'100%'} >
          <LineChart data={data}>
            <Line type="monotone" dataKey="pv" stroke="#fff" />
            <Tooltip content={<CustomTooltip />} />
            <XAxis dataKey="name" />
          </LineChart>
        </ResponsiveContainer>
      </motion.div>

      <motion.div
        variants={textVariants}
        initial="hide"
        whileInView="show"
        className={styles.text}>
        <p>{ t('historySection.text') }</p>
      </motion.div>

      <div className={styles.description}>
        <p>
          <Trans i18nKey={'historySection.description.one'} />
        </p>

        <p>
          <Trans i18nKey={'historySection.description.two'} />
        </p>
      </div>
    </section>
  )
}

export default HistorySection
