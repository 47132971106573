import React, { useContext } from 'react'
import styles from './Navigation.module.sass'
import { NavLink } from "react-router-dom"
import { ROUTES } from "../../utils/ROUTES"
import { motion } from "framer-motion"
import AppContext from "../../context/AppContext"

function Navigation({ left }) {
  const { MAIN, ABOUT, CONTACTS } = ROUTES

  const { t, setIsBurgerActive } = useContext(AppContext)

  const list = {
    hide: {
      opacity: 0,
    },

    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.15,
      },
    },
  }

  const item = {
    hide: { opacity: 0, x: -30 },
    show: { opacity: 1, x: 0 },
  }

  const handleLinkClick = () => setIsBurgerActive(false)

  return (
    <nav
      className={styles.navigation}>
      <motion.ul
        variants={list}
        initial="hide"
        whileInView="show"
        className={left ? `${styles.list} ${styles.list_direction_column}` : styles.list}>
        <motion.li onClick={handleLinkClick} variants={item}>
          <NavLink to={MAIN} className={styles.link}>{ t('navigation.main') }</NavLink>
        </motion.li>

        {
          !left &&
            <motion.li onClick={handleLinkClick} variants={item}>
              <NavLink to={MAIN} className={styles.link}>{ t('navigation.presentation') }</NavLink>
            </motion.li>
        }

        <motion.li onClick={handleLinkClick} variants={item}>
          <NavLink to={ABOUT} className={styles.link}>{ t('navigation.about') }</NavLink>
        </motion.li>
        <motion.li onClick={handleLinkClick} variants={item}>
          <NavLink to={CONTACTS} className={styles.link}>{ t('navigation.contacts') }</NavLink>
        </motion.li>
      </motion.ul>
    </nav>
  )
}

export default Navigation
