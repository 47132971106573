import React, { useState, useRef, useContext } from 'react'
import styles from './ContactUsFirstScreenSection.module.sass'
import SectionTitle from "../../SectionTitle/SectionTitle"
import Input from "../../Input/Input"
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs"
import FormSubmit from "../../FormSubmit/FormSubmit"
import { motion } from "framer-motion"
import emailjs from '@emailjs/browser'
import AppContext from "../../../context/AppContext"

function ContactUsFirstScreenSection() {
  const { t, setIsSending } = useContext(AppContext)

  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    message: '',
  })
  const formRef = useRef()

  const textVariants = {
    hide: {
      opacity: 0,
      scale: 0.95
    },

    show: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.5 }
    }
  }

  const handleSubmit = evt => {
    evt.preventDefault()
    console.log(formValues)

    setIsSending(true)

    emailjs.sendForm('service_lndg98q', 'template_9xzcnyf', formRef.current, '4mDt7Zc_afsFIqr_0')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      })
      .finally(() => setIsSending(false))
  }

  const handleChange = evt => {
    const { name, value } = evt.target

    setFormValues(prev => {
      return { ...prev, [name]: value }
    })
  }

  return (
    <section className={styles.section}>
      <div className={styles.symbol} />

      <div className={styles.leftCol}>
        <BreadCrumbs dark />

        <SectionTitle text={ t('contactsPage.title') } />

        <motion.div
          variants={textVariants}
          initial="hide"
          whileInView="show"
          className={styles.texts}>
          <p>{ t('contactsPage.text') }</p>

          <div className={styles.contacts}>
            <a href="tel:+7 (800) 600-25-88">+7 (800) 600-25-88</a>
            <a href="mailto:info@adversus-td.ru">info@adversus-td.ru</a>
          </div>
        </motion.div>

        <form
          ref={formRef}
          onSubmit={handleSubmit}
          className={styles.form}>
          <div className={styles.phoneAndEmail}>
            <Input
              required={true}
              value={formValues.name}
              onChange={handleChange}
              type={'text'}
              name={'name'}
              placeholder={ t('inputs.fullName') }
              dark />
            <Input
              required={true}
              value={formValues.email}
              onChange={handleChange}
              type={'text'}
              name={'email'}
              placeholder={ t('inputs.emailOrPhone') }
              dark />
          </div>

          <Input
            required={true}
            value={formValues.message}
            onChange={handleChange}
            type={'textarea'}
            name={'message'}
            placeholder={ t('inputs.yourMessage') }
            dark />

          <FormSubmit dark />
        </form>

      </div>

      <div className={styles.rightCol}>
        <iframe
          src="https://yandex.ru/map-widget/v1/?um=constructor%3A40049b32449042be0180f5c163fef143da7f65ec398b43446c605adef6c1b92a&amp;source=constructor"
          width="100%" height="100%" frameBorder="0"></iframe>
      </div>

    </section>
  )
}

export default ContactUsFirstScreenSection
