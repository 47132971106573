import React, { useContext } from 'react'
import styles from './FullEnergySection.module.sass'
import SliderSectionContent from "../../SliderSectionContent/SliderSectionContent"
// import AdversusLogo from "../../AdversusLogo/AdversusLogo"
import { motion } from "framer-motion"
import AppContext from "../../../context/AppContext"

function FullEnergySection() {
  const { t, pageSlider } = useContext(AppContext)

  const variants = {
    hide: {
      opacity: 0
    },

    show: {
      opacity: 1
    }
  }

  const classes = {
    section: styles.section,
    active: pageSlider === 3
      ? styles.active
      : null
  }

  return (
    <section className={`${classes.section} ${classes.active}`}>
      <div className={styles.background} />
      {/*<AdversusLogo />*/}
      <motion.div
        variants={variants}
        initial="hide"
        whileInView="show"
        transition={{ duration: 0.5, delay: 0.2 }}
        className={styles.fireCircle} />

      <motion.div
        variants={variants}
        initial="hide"
        whileInView="show"
        transition={{ duration: 0.3, delay: 0.3 }}
        className={styles.bottle} />

      <motion.div
        variants={variants}
        initial="hide"
        whileInView="show"
        transition={{ duration: 0.5, delay: 0.4 }}
        className={styles.jar} />
      <SliderSectionContent
        link={'https://full-energy.ru/'}
        text={[
          t('fullEnergy.partOne'),
          t('fullEnergy.partTwo')
        ]}>
          <h2 className={styles.title}>Full Energy</h2>
      </SliderSectionContent>
    </section>
  )
}

export default FullEnergySection
