import React, {useEffect, useState} from 'react'
import styles from './Preloader.module.sass'
import PreloaderSymbol from "./PreloaderSymbol"

function Preloader() {
  const [overlayHeight, setOverlayHeight] = useState(0)
  const [symbolColor, setSymbolColor] = useState('#fff');
  const transition = 1500

  const classes = {
    overlay: {
      height: `${overlayHeight}vh`,
      transition: `${transition}ms`
    },

    square: symbolColor === '#000'
        ? `${styles.square} ${styles.square_white}`
        : styles.square
  }

  useEffect(() => {
    setOverlayHeight(100)

    setTimeout(() => {
      setSymbolColor('#000')
    }, transition / 3)
  }, [])

  return (
    <section className={styles.section}>
      {/*<h2>Preloader</h2>*/}
      <div style={classes.overlay} className={styles.overlay}/>
      <div className={classes.square}>
        <PreloaderSymbol fill={symbolColor} />
      </div>
    </section>
  )
}

export default Preloader
