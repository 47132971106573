import React, {useEffect} from 'react'
import './Particle.sass'

function Particle() {
  useEffect(() => {
    function createCircle() {
      const section = document.querySelector("#gas");
      const circleEl = document.createElement("span");
      let size = Math.random() * 10;
      let duration = Math.random() * 20;
      circleEl.style.width = 20 + size + "px";
      circleEl.style.height = 20 + size + "px";
      circleEl.style.left = Math.random() * (section.offsetWidth / 6) + "px";
      section.appendChild(circleEl);

      setTimeout(() => {
        circleEl.remove();
      }, 4000);
    }

    setInterval(createCircle, 200);
  }, [])

  return (
    <section id="gas">

    </section>
  )
}

export default Particle
