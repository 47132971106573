import React, { useContext } from 'react'
import styles from "../sections/FeedbackSection/FeedbackSection.module.sass"
import Button from "../Button/Button"
import { motion } from "framer-motion"
import AppContext from "../../context/AppContext"

function FormSubmit({ dark }) {

  const { t, isSending } = useContext(AppContext)
  const classes = {
    text: dark
      ? `${styles.text} ${styles.text_dark}`
      : styles.text
  }

  const textVariant = {
    hide: {
      x: 100,
      opacity: 0
    },

    show: {
      x: 0,
      opacity: 0.2,
      transition: { delay: 0.2 }
    }
  }

  return (
    <div className={styles.action}>
      <Button type={'submit'} text={isSending ? t('formSubmit.button.sending') : t('formSubmit.button.submit')} bg={ dark ? 'light' : 'dark'} />

      <motion.p
        variants={textVariant}
        initial="hide"
        whileInView="show"
        className={classes.text}>
        { t('formSubmit.text') }
      </motion.p>
    </div>
  )
}

export default FormSubmit
