import React, { useContext } from 'react'
import styles from './Burger.module.sass'
import AppContext from "../../context/AppContext"
import { useLocation } from "react-router-dom"
import { ROUTES } from "../../utils/ROUTES"

function Burger() {
  const { isBurgerActive, setIsBurgerActive, isBurgerDark } = useContext(AppContext)
  const { ABOUT } = ROUTES
  const { pathname } = useLocation()

  const classes = {
    burger: isBurgerActive
      ? `${styles.burger} ${styles.burger_active}`
      : `${styles.burger} ${pathname === ABOUT ? styles.burger_page_about : ''}`,

    scrollDown: isBurgerDark && pathname === ABOUT
      ? ''
      : styles.burger_page_about_scroll_down
  }

  const handleBurgerClick = () => setIsBurgerActive(prev => !prev)

  return (
    <div onClick={handleBurgerClick} className={`${classes.burger} ${classes.scrollDown}`}>
      <div />
      <div />
      <div />
    </div>
  )
}

export default Burger
